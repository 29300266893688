import InRestCat from "../ui/InRestCat";
import Meal from "../ui/Meal";
import { useState } from "react";
import FoodViewModal from "../ui/FoodViewModal";
import { useLocation } from "react-router-dom";
function InResturant() {
  let location = useLocation();

  
  let restaurant = location.state.restaurant;
  const rest = [{
    name: "BISTROT BEN YEDDER",
    value: "images/bistrot.png",
    is:"images/bistr san.png",
      menu1:"images/bistrot_menu1.jpeg",
      menu2:"images/bistrat_menu2.jpeg"

},
{
    name: "LA ROQUETTE",
    value: "images/la roquette.png",
    is:"images/laroquette san.png",
    menu1:  "images/laroquettemenu2.jpg",
    menu2:  "images/laroquettemenu1.jpg"
},
{
    name: "WELD LAHNINA",
    value: "images/weld lahnina.jpeg",
    is:"images/weldhlima san.png",
    
    menu1:"images/weldhlima_menu1.jpeg"
  
},
{
    name: "Felicita",
    value: "felicita.jpeg",
    is:"images/feààààà.jpeg",
    menu1:  "images/Felicita Menu.png"
   
}
];
  // Now you can use the restaurant object
  console.log(restaurant);
  const [isViewingItem, setIsViewItem] = useState(false);
  const showMealDetail = () => {
    setIsViewItem(true);
  };
  const closeModal = () => {
    setIsViewItem(false);
  };
  return (
    <>
      {isViewingItem && <FoodViewModal closeModal={closeModal} />}
    <section>
      <div
        className="container-fluid pt-5 text-theme pb-5 inrest-wrapper-image"
        style={{
          backgroundImage: `linear-gradient(90deg, rgb(0 0 0 / 60%), rgb(0 0 0 / 60%)),url("${`${rest[restaurant].is}`}")`,
        }}
      >
        {/** intro text */}

        <div className="row px-md-5 mx-md-3">
          <div className="col-12 mt-4 px-4">
            <div className="h3 fw-bolder">{rest[restaurant].name}</div>
            
          </div>
        </div>

        {/** contacts */}
        <div className="row px-md-5 mx-md-3">
          <div className="col-12 px-4">
            <div className="pt-2 text-grey fs-14 d-flex-native">
              <img
                className="img-fluid"
                src="/images/Vector location.png"
                alt=""
              />
              <span className="ps-2">50 Rue tayeb lmhiri, la marsa</span>
            </div>
            <div className="pt-2 text-grey fs-14 d-flex-native">
              <img
                src="/images/Vector telephone.png"
                className="img-fluid"
                alt=""
              />
              <span className="ps-2"></span>22 387 387
            </div>

            <div className="pt-2 text-grey fs-14 d-flex-native">
              <img src="images/Vector green.png" className="img-fluid" alt="" />
              <span className="text-success ps-2">Open </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/** category buttons */}
        <div
          className="row py-3 mt-md-3 no-scroll-bar flex-nowrap "
          style={{ overflowX: "scroll" }}
        >
          {[0].map((rest, index) => (
            <InRestCat key={index} />
          ))}
        </div>

        {/** means */}
        <div className="row pt-3 px-2">
          {/** summer lunch  */}
          <div className=" fw-bold h4">
            
          </div>
        </div>
        <div className="row px-2">
          {[0].map((rest, index) => (
            <Meal key={index} />
            
          ))}
          
          
        <div className="card border-0">
          <div className="card-body px-0">
            <img
              src={`${rest[restaurant].menu1}`}
              alt=""
              className="img-fluid w-100"
            />
           
          </div>
        </div>
        <div className="card border-0">
          <div className="card-body px-0">
          <img
              src={`${rest[restaurant].menu2}`}
              alt=""
              className="img-fluid w-100"
            />
           
          </div>
        </div>
     
      
      
            
    
        </div>
      </div>
    </section>
    </>
  );
}

export default InResturant;
