/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
function Wrapper() {
  let navigate = useNavigate();

  const toRest = () => {
    navigate("/resturants");
  };
  return (
    <div
  id="bgLanding"
  className=""
  style={{
    zIndex: "1",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    minHeight: "100vh",
  }}
>
  <div className="container-fluid px-md-4 pt-md-5">
    <div className="row pt-md-5">
      <div className="pt-5 mt-5 px-4">
        
        <p className="pb-md-4 pb-lg-0 mt-md-2 mt-lg-0 text-white ps-lg-4 pt-4">
          <span className="the-food" style={{ backgroundColor: "black" }}>
            Passez votre commande à la caisse la plus proche 
          </span>
        </p>
      </div>

      <div className="ps-4 gap-2 col-11 col-md-9 col-lg-6 ps-lg-5 py-lg-5 py-2">
       
      </div>
      {/**/}
      
    </div>
  </div>
</div>


  );
}

export default Wrapper;
