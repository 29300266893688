/* eslint-disable jsx-a11y/anchor-is-valid */
function InRestCat() {
  return (
    <div className="col mt-2">
      <button
        className="btn w-mc fw-bold"
        style={{ borderBottom: "2px solid black", borderRadius: "0px" }}
      >
        menu
      </button>
    </div>
  );
}

export default InRestCat;
