import CheckoutNav from "../../../ui/CheckoutNav";

function InnerReview() {
  return (
    <div className="row pt-5 pb-5">
      <CheckoutNav />
    </div>
  );
}
export default InnerReview;
