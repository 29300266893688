import Resturant from "../ui/Resturant";
import { useNavigate } from "react-router-dom";

function Resturants() {
  let navigate = useNavigate();
  const enterRest = (restaurant) => {
    navigate("/in-resturant", { state: { restaurant } });
  };
  const rest = [{
    name: "BISTROT BEN YEDDER",
    value: "images/bistrot.png",
},
{
    name: "LA ROQUETTE",
    value: "images/la roquette.png",
},
{
    name: "WELD LAHNINA",
    value: "images/weld lahnina.jpeg",
},
{
    name: "Felicita",
    value: "images/felicita.jpeg",
}
];
  return (
    <section>
      <div className="container pt-5">
        <div className="row pt-5 justify-content-center pt-md-5">
          <div className="col-12 px-3 my-3 d-block d-md-none">
            <input
              type="text"
              placeholder="Resturants,Foods,Drinks"
              className="form-control input-n-medium bg-theme border-none"
            />
          </div>

          {/*<div className="col-12 pb-md-4 px-4">
            <span className=" fs-20 fw-bold">Our Resturants</span>
          </div>*/}
{rest.map((data, i) => {
              return (
                <div className="col-12 col-md-6 col-lg-3">
                <div className="card border-0 p-2 cur-pointer" onClick={() => enterRest(i)}>
                <img src={`${data.value}`} alt="" className="img-fluid" />

                  <div className="card-body px-0">
                    <div className="row d-flex justify-content-between">
                      <div className="col-10">
                        <h6 className="card-title restaurantCardHeader fw-bold text-uppercase">
                        {data.name}
                        </h6>
                      </div>
                      <div className="col-2 pe-2">
                        <p className="card-text rating-text">
                          <span className="rounded-circle p-1 bg-gr">4.5</span>
                        </p>
                      </div>
                    </div>
          
                    <p className="card-text restaurantCardText">
                    
                    Desctiption
                    </p>
                  </div>
                </div>
              </div>
                
              );
            })}
          
        </div>
      </div>
    </section>
  );
}

export default Resturants;
