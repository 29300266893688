import { useState } from "react";
import FoodViewModal from "../ui/FoodViewModal";

function Meal() {
  const [isViewingItem, setIsViewItem] = useState(false);
  const showMealDetail = () => {
    setIsViewItem(true);
  };
  const closeModal = () => {
    setIsViewItem(false);
  };
  return (
    <>
      {isViewingItem && <FoodViewModal closeModal={closeModal} />}
      <div
        className="col-12 col-md-6 col-lg-3 cur-pointer"
        onClick={showMealDetail}
      >
        <div className="card border-0">
          <div className="card-body px-0">
          
          </div>
        </div>
      </div>
    </>
  );
}

export default Meal;
